import { BubbleDataPoint, ChartDataset } from 'chart.js';

export interface ExtendedBubbleDataPoint<T> extends BubbleDataPoint {
  data: T;
}
export interface ExtendedChartDataset<T> extends ChartDataset<'bubble'> {
  data: ExtendedBubbleDataPoint<T>[];
}
export type CompanyDatasetsMap<T> = {
  [companyName: string]: ExtendedChartDataset<T>;
};

export function calculateR(value: number, bigValue: number) {
  const minRadius = 2;
  const isBigScreen = window.innerWidth >= 1024;
  const bigRadius = isBigScreen ? 50 : 20;
  const maxRadius = isBigScreen ? 100 : 25;
  return Math.min(
    Math.max(Math.sqrt(value / bigValue) * bigRadius, minRadius),
    maxRadius
  );
}

export function filterDatasetsMap<T>(
  keyword: string,
  datasetsMap: CompanyDatasetsMap<T>,
  matcher: (row: T) => boolean
) {
  let datasets: ExtendedChartDataset<T>[] = [];
  for (const companyName of Object.keys(datasetsMap)) {
    const dataset = { ...datasetsMap[companyName] };
    if (dataset.label?.toLowerCase().includes(keyword.toLowerCase())) {
      datasets.push(dataset);
      continue;
    }
    const data: ExtendedBubbleDataPoint<T>[] = [];
    for (const d of dataset.data) {
      if (matcher(d.data)) {
        data.push(d);
      }
    }
    if (data.length > 0) {
      dataset.data = data;
      datasets.push(dataset);
    }
  }
  return datasets;
}
