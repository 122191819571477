import { Chart } from 'chart.js';
import {
  calculateR,
  CompanyDatasetsMap,
  ExtendedChartDataset,
} from './dataset';
import { debounce } from './debounce';
import { BubbleTooltipHandler, hideTooltip } from './tooltip';

interface InitChartProps<T> {
  datasetsMap: CompanyDatasetsMap<T>;
  xTicksCallback?: (value: string | number) => string | number;
  externalTooltipHandler?: BubbleTooltipHandler;
  onSearch?: (keyword: string) => ExtendedChartDataset<T>[];
}

let bigValue = 1;

export function initChart<T>(props: InitChartProps<T>) {
  const chart = new Chart(
    document.getElementById('bubble-chart') as HTMLCanvasElement,
    {
      type: 'bubble',
      options: {
        maintainAspectRatio: false,
        layout: {
          autoPadding: true,
          padding: {
            right: window.innerWidth >= 1024 ? 52 : 22,
          },
        },
        events: ['click', 'mouseenter', 'mousemove'],
        scales: {
          y: {
            type: 'time',
            time: {
              minUnit: 'month',
            },
            ticks: {
              color: '#000',
            },
          },
          x: {
            ticks: {
              color: '#000',
              callback: props.xTicksCallback,
              minRotation: 45,
            },
            position: 'top',
            type: 'logarithmic',
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            external: props.externalTooltipHandler,
          },
        },
      },
      data: {
        datasets: Object.keys(props.datasetsMap).map((companyName) => {
          return props.datasetsMap[companyName];
        }),
      },
    }
  );
  calculateBigValue(chart.data.datasets as ExtendedChartDataset<T>[]);
  updateR(chart.data.datasets as ExtendedChartDataset<T>[]);
  setTimeout(() => chart.update(), 1000);

  let lastQuery = '';
  const mainEl = document.getElementById('main') as HTMLDivElement;
  let timeoutId = 0;
  document.getElementById('search')?.addEventListener(
    'keyup',
    debounce((e: { target: { value: string } }) => {
      if (e.target.value === lastQuery || !props.onSearch) return;
      const datasets = props.onSearch(e.target.value);
      bigValue = 1;
      const ctr = calculateBigValue(datasets);
      const tHeight = ctr <= 50 ? '400px' : '1600px';
      if (mainEl.style.height === tHeight) {
        chart.data.datasets = datasets;
        updateR(datasets);
        hideTooltip();
        chart.update();
      } else {
        chart.data.datasets = [];
        mainEl.style.height = tHeight;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          chart.data.datasets = datasets;
          updateR(datasets);
          hideTooltip();
          chart.update();
        }, 100);
      }
      lastQuery = e.target.value;
    }, 50)
  );

  window.addEventListener(
    'resize',
    debounce(() => {
      updateR(chart.data.datasets as ExtendedChartDataset<T>[]);
      chart.options.animation = false;
      if (chart.options.layout) {
        chart.options.layout.padding = {
          right: window.innerWidth >= 1024 ? 52 : 22,
        };
      }
      chart.update();
    }, 1000)
  );

  function calculateBigValue(datasets: ExtendedChartDataset<T>[]) {
    let ctr = 0;
    for (const ds of datasets) {
      ctr += ds.data.length;
      for (const d of ds.data) {
        if (d.x > bigValue) bigValue = d.x;
      }
    }
    return ctr;
  }

  function updateR(datasets: ExtendedChartDataset<T>[]) {
    for (const ds of datasets) {
      for (const d of ds.data) {
        d.r = calculateR(d.x, bigValue);
      }
    }
  }

  return { chart };
}
