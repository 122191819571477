import { Chart, TooltipModel } from 'chart.js';
import { ExtendedBubbleDataPoint } from './dataset';

const tooltipWidth = 180;

let tooltipRef: { current: HTMLDivElement | null } = { current: null };

const getOrCreateTooltip = (chart: Chart) => {
  let tooltipEl = chart.canvas.parentNode?.querySelector(
    'div'
  ) as HTMLDivElement;

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(255, 255, 255, 0.9)';
    tooltipEl.style.fontSize = '14px';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'black';
    tooltipEl.style.display = 'block';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transition = 'all .2s ease';
    tooltipEl.style.width = tooltipWidth + 'px';
    tooltipEl.style.boxSizing = 'border-box';

    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  tooltipRef.current = tooltipEl;

  return tooltipEl;
};

export function hideTooltip() {
  if (tooltipRef.current) tooltipRef.current.style.display = 'none';
}

export type BubbleTooltipHandler = (
  this: TooltipModel<'bubble'>,
  args: {
    chart: Chart<'bubble'>;
    tooltip: TooltipModel<'bubble'>;
  }
) => void;

interface TooltipRenderer<T> {
  (data: ExtendedBubbleDataPoint<T>): string;
}

export const createExternalTooltipHandler =
  <T>(tooltipRenderer: TooltipRenderer<T>): BubbleTooltipHandler =>
  (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.display = 'none';
      return;
    }

    tooltipEl.innerHTML = '';
    for (let i = 0; i < tooltip.dataPoints.length; i++) {
      const dp = tooltip.dataPoints[i];
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      span.style.background = colors.backgroundColor.toString();
      span.style.borderColor = colors.borderColor.toString();
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.display = 'inline-block';

      const t = tooltipRenderer(dp.raw as ExtendedBubbleDataPoint<T>);
      tooltipEl.appendChild(span);
      tooltipEl.innerHTML += t;
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position
    tooltipEl.style.display = 'block';
    if (positionX + tooltip.caretX > window.innerWidth / 2) {
      tooltipEl.style.left = positionX + tooltip.caretX - tooltipWidth + 'px';
    } else {
      tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    }
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.padding =
      tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };
